import { useEffect, useState } from "react";
import { fetchOrgConfig } from "src/api/org";
import { useOrgContext } from "src/contexts/org-context";

export const useConfigFromApi = (
  org_slug: string | undefined,
  authed?: boolean
) => {
  const {
    setOrgSlug,
    setOrgLogo,
    setBotName,
    setBotIcon,
    setChatTitle,
    setPageTitle,
    setBotGreetingMsg,
    setBotTypingMsg,
    setPrimaryColor,
    setSecondaryColor,
    configLoadedFromApi,
    setConfigLoadedFromApi,
    setFirstName,
    setLastName,
    setHasTodayItems,
  } = useOrgContext();
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    if (!org_slug) {
      setConfigLoaded(true);
      return;
    }

    if (configLoadedFromApi) {
      // Don't fetch again if already fetched
      setConfigLoaded(true);
      return;
    }

    fetchOrgConfig(org_slug, authed ? authed : false).then((data) => {
      const config = data?.config;

      if (!config) {
        return;
      }

      setOrgSlug(org_slug);
      setOrgLogo(config.logo || "/perpetual_bot_icon.png");
      setBotName(config.bot_name);
      if (config.botIcon) {
        setBotIcon(config.bot_icon);
      }
      setChatTitle(config.chat_title);
      setPageTitle(config.page_title);
      setBotGreetingMsg(config.bot_greeting);
      setBotTypingMsg(config.bot_typing);
      setPrimaryColor(config.primary_color);
      setSecondaryColor(config.secondary_color);
      setHasTodayItems(config.has_today_items);

      setConfigLoaded(true);

      setConfigLoadedFromApi(true);

      if (data.me) {
        setFirstName(data.me.first_name);
        setLastName(data.me.last_name);
      }

      return config;
    });
  }, [
    org_slug,
    setConfigLoaded,
    setOrgSlug,
    setOrgLogo,
    setBotName,
    setBotIcon,
    setChatTitle,
    setPageTitle,
    setBotGreetingMsg,
    setBotTypingMsg,
    setPrimaryColor,
    setSecondaryColor,
    setConfigLoadedFromApi,
    configLoadedFromApi,
    setFirstName,
    setLastName,
    setHasTodayItems,
    authed,
  ]);

  return {
    configLoaded: configLoaded || configLoadedFromApi,
  };
};
